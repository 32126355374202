













































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { CustomerPaymentInfoProperties } from "./CustomerPaymentInfoView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { CustomerPaymentReceiptProperties } from "./CustomerPaymentReceiptView.vue";
import { CustomerPaymentAddressProperties } from "./CustomerPaymentAddressView.vue";
import { CustomerPaymentModifyProperties } from "./CustomerPaymentModifyView.vue";
import DateUtil from '@/utilities/DateUtil';

@Component({
    data: () => ({
        hrefShippingAddress: null,
        hrefSummaryDetail: null
    })
})
export default class CustomerPaymentSummaryView extends Vue {
    @Prop() private properties: CustomerPaymentInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    @Prop() private customerPaymentReceipt: CustomerPaymentReceiptProperties;
    @Prop() private customerPaymentAddress: CustomerPaymentAddressProperties;
    @Prop() private customerPaymentModify: CustomerPaymentModifyProperties;

    public get deliveryOptions() {
        return [
            {
                text: this.$t("text.standard-delivery"),
                value: "Standard-Delivery",
            },
            {
                text: this.$t("text.on-hold-delivery"),
                value: "On-Hold-Delivery",
            },
            {
                text: this.$t("text.store-pickup"),
                value: "Store-Pickup",
            },
        ];
    }

    public get payment() {
        return this.properties.payment;
    }

    public get isDeposit() {
        return this.payment.type === "Deposit";
    }

    public get isCheckout() {
        return this.payment.type === "Checkout";
    }

    public get isReceipt() {
        const paymentMethod = this.payment.paymentMethod;
        return paymentMethod === "Receipt" || paymentMethod === "Mixed";
    }

    public get isWallet() {
        const paymentMethod = this.payment.paymentMethod;
        return paymentMethod === "Wallet" || paymentMethod === "Mixed";
    }

    public get isCash() {
        const paymentMethod = this.payment.paymentMethod;
        return paymentMethod === "Cash" || paymentMethod === "Mixed";
    }

    public get isDraft() {
        return this.payment.status === 'Draft';        
    }

    public get isProcessing() {
        return this.payment.status === "Processing";
    }

    public get status() {
        const v = this.payment.status;
        switch (v) {
            case "Draft": return this.$t('text.draft');
            case "Processing": return this.$t("text.processing");
            case "Approved": return this.$t("text.approved");
            case "Cancelled": return this.$t("text.cancelled");
            case "Refunded": return this.$t("text.refunded");
            case "Rejected": return this.$t("text.rejected");
            case "Delivered": return this.$t("text.delivered");
            case "Completed": return this.$t("text.completed");
        }
        return v;
    }

    public get type() {
        const v = this.payment.type;
        switch (v) {
            case "Deposit": return this.$t("text.deposit");
            case "Checkout": return this.$t("text.checkout");
        }
        return v;
    }

    public get paymentMethod() {
        const v = this.payment.paymentMethod;
        switch (v) {
            case "Cash": return this.$t("text.cash");
            case "Wallet": return this.$t("text.wallet");
            case "Receipt": return this.$t("text.receipt");
            case "Mixed": return this.$t("text.mixed");
        }
        return v;
    }

    public get paymentDate() {
        const v = this.payment.paymentDate;
        const f = this.properties.dateTimeFormat;
        return v ? DateUtil.format(new Date(v), f) : "";
    }

    public get shipping() {
        return this.properties.payment.shipping;
    }

	public get customer() {
		return this.payment.customer;
	}
	
	public get customerName() {
		const f = this.facebookAccount;
		const n = this.customer.name;
		const u = f.uniqueNo ?? null;
		return u !== null ? `${n} [${u}]` : n;
	}

	public get customerUrl() {
		return `/management/customers/modify?id=${this.customer.id}`;
	}

	public get facebookAccount() {
		return this.customer.facebookAccount;
	}

    public get currency() {
        return this.properties.currency;
    }

    public get totalPayment() {
        return this.payment.cashPayment + this.payment.walletPayment + this.payment.receiptPayment;
    }

    public isDelivery(deliveryMethod: string) {
        return this.payment.deliveryMethod === deliveryMethod;
    }

    public mounted() {
        this.properties.events.subscribe('loaded', this.loaded);
        this.loaded();
    }

    public destroyed() {
        this.properties.events.remove('loaded', this.loaded);
    }

    public selectShipping() {
        if (this.isDraft || this.isProcessing) {
            this.customerPaymentAddress.visible = true;
        }
    }

    public modifyDetail(e: Event) {
        if (this.isDraft || this.isProcessing) {
            this.customerPaymentModify.visible = true;
        }
        e.preventDefault();
        return false;
    }

    public viewReceipt() {
        this.customerPaymentReceipt.visible = true;
    }

    public async loaded() {
        var elShippingAddress: HTMLElement = this.$el.querySelector("#shipping-address");
        var elSummaryDetail: HTMLElement = this.$el.querySelector("#summary-detail");

        if (this.isCheckout && (this.isDraft || this.isProcessing)) {
            this.$data.hrefShippingAddress = "javascript:void(0);";
            this.$data.hrefSummaryDetail = "javascript:void(0);";
            await Vue.nextTick();
            elShippingAddress = this.$el.querySelector("#shipping-address");
            elSummaryDetail = this.$el.querySelector("#summary-detail");
            elShippingAddress.addEventListener('click', this.selectShipping);
            elSummaryDetail.addEventListener('click', this.modifyDetail);
        } else if (this.isDeposit && (this.isDraft || this.isProcessing)) {
            this.$data.hrefSummaryDetail = "javascript:void(0);";
            await Vue.nextTick();
            elSummaryDetail = this.$el.querySelector("#summary-detail");
            elSummaryDetail.addEventListener('click', this.modifyDetail);
        }
    }
}
